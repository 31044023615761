.carousel_webview{
  margin: auto;
  margin-bottom: 7px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  /* padding: 0px 12px; */
}
.carousel_title{
  margin-top: 0px;
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 0px 12px;
  margin-bottom: 4px;
}
.carousel{
  overflow-x: scroll;
  display: -webkit-box;
  -webkit-box-pack: center;
  overflow-y: hidden;
  padding-top: 3px;
}
.social_feed_main > hr{
  max-width: 600px;
  border: 0px;
  background: #aaa;
  margin: auto;
}
.carousel_card{
  display: block;
  /* background: #4071cc; */
  /* color: white; */
  width: var(--carousel-card-width);
  height: var(--carousel-card-height);
  border-radius: 8px;
  cursor: pointer;
  /* padding: 4px; */
  margin-right: 16px;
  margin-bottom: 10px;
}
.carousel_card:nth-of-type(1){
  margin-left: 16px;
}
.carousel_card > img{
  /* width: 35px;
  height: 30px;
  margin: 6px 0px 16px 6px; */
  object-fit: cover;
  width: fit-content;
  height: var(--carousel-card-height);
  width: var(--carousel-card-width);
  border-radius: 10px;
  box-shadow: 0px 2px 8px #d2d2d2;
  /* image-rendering: -webkit-optimize-contrast; */
}
.carousel_card:focus-visible{
  /* outline: #aaa solid 2px; */
  outline: none;
}
.product_name{
  position: relative;
  top: -70px;
  visibility: hidden;
  display: none;
}

.card_loading_animation {
  background: #fff;
  border-radius: 10px;
}
.card_loading_image{
  width: calc(var(--carousel-card-width));
  height: calc(var(--carousel-card-height));
  border-radius: 8px;
  box-shadow: 0px 2px 8px #d2d2d2;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
.card_loading_image{
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

