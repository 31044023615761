@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.web_app{
  display: flex;
  justify-content: center;
}

h1{
  font-family: 'Poppins'
}
p{
  font-family: 'Poppins'
}
.navbar {
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  margin: auto;
  justify-content: space-between;
  max-width: 600px;
  border-top: 1px solid rgb(218, 214, 214)
  
}

.navbar a {
  float: left;
  width: 20%;
  display: block;
  color: #f2f2f2;
  text-align: center;
  /* padding: 7px 7px; */
  text-decoration: none;
  font-size: 14px;
  justify-content: space-between,
}


.navbar a:hover {
  /* background: green; */
  color: #f2f2f2;
}

.navbar a.active {
  /* background-color: #4CAF50; */
  color: white;
}
.navbar svg{
  margin-bottom: 0px;
  margin-top: 5px
}
.navbar p{
  font-size: 10px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.snack_bar1{
  display: none;
}
.snack_bar{
    position: fixed;
    bottom: 0;
    /* border: 1px solid black; */
    height:117px;
    width: 100%;
    /* border-radius: 20px; */
    /* transition: 2s linear; */
    animation:snack_bar 1s linear;
    max-width: 600PX;
    z-index: 10000;
    background-color: #ffffff;
    padding-top:10px;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    padding-left: 10px;
    padding-right: 10px;
    /* display: flex */
}

.snack_bar h1{
  color: #2e7d32;
  font-family: 'Poppins'
}

.snack_img_div{
  width:30%;
  /* border:1px solid; */
  display: flex;
  align-items: center
}
.snack_img_div h1{
  color: #2e7d32;
  font-family: 'Poppins';
  line-height: 20px;
  font-size: 16px;
  margin-left: 15px;
}
.snack_img_div p{
  color: #2e7d32;
  font-family: 'Poppins';
  line-height: 20px;
  font-size: 16px;
  margin-left: 15px;

}
.snack_img_div svg{
  position: absolute;
}
.snack_img_div img{
  width:44px;
  height:44px;
  margin-top:0px;
  object-fit: fill;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 44px;
  max-height: 44px;
}
.snack_bar_download{

  background-color: #2e7d32;
  margin:auto;
  width: 85%;
  margin-top:5px;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 34px;
  border-radius: 4px;

}
.snack_bar_download p{
display: flex;
color: #ffffff;
align-items: center;
justify-content: center;
margin:auto;
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
}
.snack_bar_download label{
 margin-left: 5px;
 padding-top:4px;
}
.snack_bar_info{
  display: flex;
  width: 100%;
}
@keyframes snack_bar {
  from {
    height:0px
  }
  to {
    height:117px;
  }
}

.App-link {
  color: #61dafb;
}
.feed{
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: auto
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full_scr_loader{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: auto;

}

.loader_content{
  border:1px solid #f8f8f8;
  width: 80%;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  
}

