@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mukta&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.web_app{
  display: flex;
  justify-content: center;
}

h1{
  font-family: 'Poppins'
}
p{
  font-family: 'Poppins'
}
.navbar {
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  margin: auto;
  justify-content: space-between;
  max-width: 600px;
  border-top: 1px solid rgb(218, 214, 214)
  
}

.navbar a {
  float: left;
  width: 20%;
  display: block;
  color: #f2f2f2;
  text-align: center;
  /* padding: 7px 7px; */
  text-decoration: none;
  font-size: 14px;
  justify-content: space-between,
}


.navbar a:hover {
  /* background: green; */
  color: #f2f2f2;
}

.navbar a.active {
  /* background-color: #4CAF50; */
  color: white;
}
.navbar svg{
  margin-bottom: 0px;
  margin-top: 5px
}
.navbar p{
  font-size: 10px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.snack_bar1{
  display: none;
}
.snack_bar{
    position: fixed;
    bottom: 0;
    /* border: 1px solid black; */
    height:117px;
    width: 100%;
    /* border-radius: 20px; */
    /* transition: 2s linear; */
    -webkit-animation:snack_bar 1s linear;
            animation:snack_bar 1s linear;
    max-width: 600PX;
    z-index: 10000;
    background-color: #ffffff;
    padding-top:10px;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    padding-left: 10px;
    padding-right: 10px;
    /* display: flex */
}

.snack_bar h1{
  color: #2e7d32;
  font-family: 'Poppins'
}

.snack_img_div{
  width:30%;
  /* border:1px solid; */
  display: flex;
  align-items: center
}
.snack_img_div h1{
  color: #2e7d32;
  font-family: 'Poppins';
  line-height: 20px;
  font-size: 16px;
  margin-left: 15px;
}
.snack_img_div p{
  color: #2e7d32;
  font-family: 'Poppins';
  line-height: 20px;
  font-size: 16px;
  margin-left: 15px;

}
.snack_img_div svg{
  position: absolute;
}
.snack_img_div img{
  width:44px;
  height:44px;
  margin-top:0px;
  object-fit: fill;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 44px;
  max-height: 44px;
}
.snack_bar_download{

  background-color: #2e7d32;
  margin:auto;
  width: 85%;
  margin-top:5px;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 34px;
  border-radius: 4px;

}
.snack_bar_download p{
display: flex;
color: #ffffff;
align-items: center;
justify-content: center;
margin:auto;
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
}
.snack_bar_download label{
 margin-left: 5px;
 padding-top:4px;
}
.snack_bar_info{
  display: flex;
  width: 100%;
}
@-webkit-keyframes snack_bar {
  from {
    height:0px
  }
  to {
    height:117px;
  }
}
@keyframes snack_bar {
  from {
    height:0px
  }
  to {
    height:117px;
  }
}

.App-link {
  color: #61dafb;
}
.feed{
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: auto
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.full_scr_loader{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: auto;

}

.loader_content{
  border:1px solid #f8f8f8;
  width: 80%;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  
}


.web_app{
    display: flex;
    justify-content: center;
}
.landing_page_container
{
    width: 100%;
    max-width: 400px;
    box-shadow: 10px;
}

@media screen and (max-width: 600px) {
    .landing_page_container
    {
        max-width: 100%;
    }
}
    

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
  }

@keyframes spin {
    to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
  }


.carousel_webview{
  margin: auto;
  margin-bottom: 7px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  /* padding: 0px 12px; */
}
.carousel_title{
  margin-top: 0px;
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 0px 12px;
  margin-bottom: 4px;
}
.carousel{
  overflow-x: scroll;
  display: -webkit-box;
  -webkit-box-pack: center;
  overflow-y: hidden;
  padding-top: 3px;
}
.social_feed_main > hr{
  max-width: 600px;
  border: 0px;
  background: #aaa;
  margin: auto;
}
.carousel_card{
  display: block;
  /* background: #4071cc; */
  /* color: white; */
  width: var(--carousel-card-width);
  height: var(--carousel-card-height);
  border-radius: 8px;
  cursor: pointer;
  /* padding: 4px; */
  margin-right: 16px;
  margin-bottom: 10px;
}
.carousel_card:nth-of-type(1){
  margin-left: 16px;
}
.carousel_card > img{
  /* width: 35px;
  height: 30px;
  margin: 6px 0px 16px 6px; */
  object-fit: cover;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: var(--carousel-card-height);
  width: var(--carousel-card-width);
  border-radius: 10px;
  box-shadow: 0px 2px 8px #d2d2d2;
  /* image-rendering: -webkit-optimize-contrast; */
}
.carousel_card:focus-visible{
  /* outline: #aaa solid 2px; */
  outline: none;
}
.product_name{
  position: relative;
  top: -70px;
  visibility: hidden;
  display: none;
}

.card_loading_animation {
  background: #fff;
  border-radius: 10px;
}
.card_loading_image{
  width: calc(var(--carousel-card-width));
  height: calc(var(--carousel-card-height));
  border-radius: 8px;
  box-shadow: 0px 2px 8px #d2d2d2;
}
@-webkit-keyframes shine {
  to {
    background-position-x: -200%;
  }
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
.card_loading_image{
  background: #eee;
  background: -webkit-linear-gradient(340deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  -webkit-animation: 1.5s shine linear infinite;
          animation: 1.5s shine linear infinite;
}


